.s-o-s-pet{
  background-image: url("../assets/images/icone-s-o-s-pet.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.s-o-s-pet-plus{
  background-image: url("../assets/images/icone-s-o-s-pet.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.s-o-s-pet-master{
  background-image: url("../assets/images/icone-s-o-s-pet.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.slim{
  background-image: url("../assets/images/icone-slim.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.flex {
  background-image: url("../assets/images/icone-flex.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.premium {
  background-image: url("../assets/images/icone-premium.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.advance{
  background-image: url("../assets/images/icone-advance.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.advance-baby{
  background-image: url("../assets/images/icone-advance-baby.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.diamond {
  background-image: url("../assets/images/icone-diamond.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.diamond-baby {
  background-image: url("../assets/images/icone-diamond-baby.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.platinum {
  background-image: url("../assets/images/icone-platinum.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.platinum-baby {
  background-image: url("../assets/images/icone-platinum-baby.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}