.credit-card {
  background: linear-gradient(233.65deg, $primary -22.76%, $secondary 113.41%);

  display: flex;
  flex-direction: column;

  justify-content: space-evenly;

  padding: 10px;
  border-radius: 5px;

  height: 150px;

  margin-bottom: 1rem;

  .logomarca {
    width: 100%;

    display: flex;
    justify-content: center;

    img {
      width: 70px;
    }
  }

  .credit-card-flag {
    img {
      width: 50px;
      height: 30px;
      object-fit: contain;
    }
  }

  .credit-card-info {
    display: flex;
    justify-content: space-between;

    flex-wrap: wrap;

    width: 100%;

    .credit-card-number {
      width: 100%;

      p {
        color: #fff;

        font-family: $font;
        font-size: 1rem;

        &:empty:not(:focus):before {
          content: attr(data-text);
        }
      }
    }

    .credit-card-name {
      span {
        color: #fff;

        font-size: 0.8rem;
        font-weight: 300;
        font-family: $font;
      }

      p {
        color: #fff;

        font-size: 0.8rem;
        font-weight: bold;
        font-family: $font;

        &:empty:not(:focus):before {
          content: attr(data-text);
        }
      }
    }

    .credit-card-date {
      span {
        color: #fff;

        font-size: 0.8rem;
        font-weight: 300;
        font-family: $font;
      }

      p {
        color: #fff;

        font-size: 0.8rem;
        font-weight: bold;
        font-family: $font;

        &:empty:not(:focus):before {
          content: attr(data-text);
        }
      }
    }
  }

  .carg-stripe-black {
    background-color: $black-brown;

    height: 40px;

    padding: 5px;

    margin-bottom: 1rem;
  }

  .carg-stripe-code {
    background-color: $white;

    height: 40px;
    width: 70%;

    padding: 5px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;

    p {
      font-family: $font;
      font-size: 1rem;
    }

    &::before {
      content: "cvv";

      font-family: $font;
      font-size: 0.7rem;
    }
  }
}
