.wrapper {
  display: flex;

  justify-content: center;

  min-height: 100%;

  background: linear-gradient(233.65deg, $primary -22.76%, $secondary 113.41%);

  .card {
    border-radius: 1.5rem !important;

    .card-body {
      .title {
        width: 73%;
        margin: auto;

        @media (max-width: 768px) {
          width: 90%;

          h1{
            font-size: 1.2rem;
          }
        }
      }
    }
  }
}


