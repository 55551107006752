ul.steps {
  list-style: none;

  padding: 0;
  
  margin-top: 2rem;
  margin-bottom: 2rem;

  display: flex;

  justify-content: center;
  align-items: center;

  gap: 10px;

  li {
    font-family: $font;
    font-weight: bold;
    font-size: 0.9rem;

    color: $gray;

    .bollet {
      background-color: $gray;

      width: 10px;
      height: 10px;

      border-radius: 10px;

      &.active {
        background-color: $primary !important;
      }
    }
  }
}
