$primary: #0080ff;
$secondary: #15bab6;

$gray: #c4c4c4;
$gray-light: #eeeeee;
$gray-dark: #504F4B;

$black-brown: #0c0505;
$white: #fff;

$green: #00C700;

$font: "Ubuntu", sans-serif;
