.form-control {
  border-radius: 20px !important;
  font-family: var(--font);

  &:focus {
    outline: none;
    box-shadow: none;
  }
}

::placeholder {
  font-family: var(--font);
}
