.container-congratulation {
  font-size: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 3rem 1rem 0 1rem;

  a{
    text-decoration: none;
    color: inherit;
  }
}

#img-dogs {
  display: flex;
  justify-content: center;
}

.title-page {
  color: $primary;
  width: 100%;
}

.rectangle-gray {
  max-width: 100%;
  height: 60px;
  padding: 0 2rem;
  background-color: $gray-light;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  .circle {
    border: 4px solid #ff451a;
    color: #ff451a;
    font-weight: bold;
  }

  div:nth-child(2) {
    margin: 0;
    font-weight: bold;
    color: #ff451a;
  }
}

.subtitle {
  color: $secondary;

  p {
    margin: 0;
  }
}

.icons-store {
  margin: 0;
  div {
    padding: 0;
  }
  div img {
    max-width: 100%;
  }
  div:first-child {
    text-align: end;
  }
  div:nth-child(2) {
    text-align: start;
  }
}

.list-steps {
  li {
    list-style: none;
    padding: 1.3rem 0;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  li:nth-child(1),
  li:nth-child(2) {
    border-bottom: 1px solid $gray;
  }

  span {
    font-weight: bold;
  }

  .circle {
    color: $green;
    border: 2px solid $green;
  }
}

.footer-congratulation-page {
  width: 100%;
  
  background: $primary;
  border-radius: 10px;
  position: relative;
  padding: 1rem;
  color: #ffffff;
  p{
    margin-bottom: 0;
  }

  div {
    height: 100%;
    max-width: 70%;
  }

  img {
    position: absolute;
    bottom: 0;
    right: 5px;
  }
}

.list-social-media {
  width: 180px;
  height: 50px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;

  li {
    list-style: none;
    background-color: $gray;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 20px;
  }

  li:hover{
    filter: brightness(1.1);
    a{
      color: #949393;
    }
  }

  li a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: #ffffff;
    text-decoration: none;
  }
}

.circle {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
}

@media screen and (max-width: 728px) {
  .container-congratulation {
    font-size: 12px;
    padding: 1rem 1rem 0 1rem;
    position: relative;

    .footer-congratulation-page {
      position: absolute;
      bottom: -80px;

      img {
        right: 15px;
        width: 100px;
        height: 120px;
      }
    }
  }

  .title-page {
    display: none;
  }

  .list-social-media {
    display: none;
  }

  .card-body {
    padding: 0;
  }
  #img-dogs{
      display: none;
  }
}
