html,
body,
#root {
  height: 100vh;
}

h1 {
  font-family: $font;
  font-size: 1.4rem;
  font-weight: bold;
}

h2 {
  font-family: $font;
  font-size: 1rem;
  font-weight: bold;
}

.btn-default {
  padding: 0;

  color: $primary;

  &:hover {
    color: $secondary;
  }
}

.btn-secondary{
  background-color: $gray-light;
  border-color: $gray-light;

  color: $gray;
}

.btn-trash {
  border: none;

  padding: 10px;

  font-size: 1rem;
  line-height: 0;

  &:hover {
    color: $primary !important;
  }
}

.btn-rounded {
  border-radius: 50px;
}

.css-1s2u09g-control {
  border-radius: 50px !important;
}
