@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap");

@import "variables";
@import "base";
@import "form";
@import "template";
@import "steps";
@import "form-payment";
@import "credit-card";
@import "modalUseTerm";
@import "congratulation";
@import "templateBlank";
@import "plans";
