.payment-credit-card {
  border: 1px solid $primary;
  border-radius: 15px;

  padding: 15px;
}

.payment-bar-code {
  border: 1px solid $primary;
  border-radius: 15px;

  min-height: 100%;

  padding: 15px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h2 {
    text-align: center;
    font-family: $font;
  }

  .bar-code-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    gap: .5rem;

    margin-bottom: 1rem;

    p{
      font-size: 0.8rem;
      font-family: $font;

      text-align: center;
    }
  }

  .bar-code-warning {
    background-color: $gray-light;

    border-radius: 10px;

    padding: 10px;

    .title {
      display: flex;
      align-items: center;

      width: 100% !important;

      color: red;

      .icon {
        width: 10%;
        font-size: 1rem;

        line-height: 0;
      }

      h3 {
        font-family: $font;
        font-size: 1rem;

        margin-bottom: 0;
      }
    }

    ul {
      padding-left: 0;
      list-style: none;

      margin-top: 1rem;
      margin-bottom: 0;

      li {
        font-family: $font;
        font-size: 0.7rem;

        text-align: justify;

        margin-bottom: 0.1rem;
      }
    }
  }
}

.plan {
  border: 1px solid $primary;
  border-radius: 15px;

  min-height: 100%;

  padding: 15px;

  display: flex;
  flex-direction: column;

  justify-content: space-between;

  .plan-header {
    display: flex;
    justify-content: space-between;

    .plan-image {
      width: 30%;
    }

    .plan-title {
      display: flex;
      flex-direction: column;

      width: 65%;

      p {
        font-size: 0.8rem;
        font-family: $font;

        color: $gray;
      }

      h2 {
        font-size: 1.5rem;
        font-family: $font;

        color: $gray-dark;
      }

      .plan-info {
        display: flex;
        align-items: center;

        gap: 0.3rem;

        p {
          &:first-child {
            font-size: 2.5rem;
          }

          color: $primary;

          font-size: 0.6rem;
          font-weight: 500;
          font-family: $font;

          line-height: 1rem;

          strong {
            display: block;
          }
        }
      }
    }
  }

  .plan-cupom{
    margin-top: 1rem;
  }

  .plan-details {
    ul {
      list-style: none;
      padding: 0;

      display: flex;
      flex-direction: column;

      li {
        padding: 10px;
        border-bottom: 1px solid $gray;

        display: flex;
        justify-content: space-between;

        .info-link{
          cursor: pointer;
        }

        span {
          font-size: 1rem;
          font-family: $font;
          font-weight: bold;
        }
      }
    }
  }

  .plan-payment-form {
    ul {
      list-style: none;
      padding: 0;

      display: flex;
      justify-content: space-between;

      gap: 10px;

      margin: 0;

      li {
        width: 100%;
      }
    }
  }
}
